import React, { useEffect, useRef } from 'react';
import FixedBackgroundSection from '../components/FixedBackgroundSection';
import CenteredImageSection from '../components/CenteredImageSection';
import '../styles/Home.css';
import firstImage from '../assets/images/first.png';
import background from '../assets/images/배경.png'; // 원래 배경 이미지로 복원
import defaultImage from '../assets/images/무료상담2.png';
import hoverImage from '../assets/images/무료상담1.png';
import section2icon1 from '../assets/images/식품제조.png';
import section2icon2 from '../assets/images/식자재유통.png';
import section2icon3 from '../assets/images/프랜차이즈.png';
import section2icon4 from '../assets/images/음식점.png';
import barImage from '../assets/images/bar.png';
import downImage from '../assets/images/down.png';
import consultingImage from '../assets/images/식품컨설팅은다르다.png';
import FixedContactIcons from '../components/FixedContactIcons';
import Page3 from './Page3'; // pages 폴더에서 import

const Home = () => {
    const sectionRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach((entry, index) => {
                    if (entry.isIntersecting) {
                        entry.target.style.setProperty('--delay', `${index * 0.7}s`); // 애니메이션 지연 시간 설정
                        entry.target.classList.add('show');
                    } else {
                        entry.target.classList.remove('show');
                    }
                });
            },
            { threshold: 0.1 }
        );

        sectionRefs.current.forEach(ref => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            sectionRefs.current.forEach(ref => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };
    }, []);

    return (
        <div className="home">
            <FixedBackgroundSection />
            <CenteredImageSection
                background={background} // 원래 배경 이미지로 복원
                image={firstImage}
                defaultImage={defaultImage}
                hoverImage={hoverImage}
                link="/page3"
            />
            <div className="bar-container">
                <img src={barImage} alt="Bar" className="bar-image" />
            </div>

            <div className="title h3">
                운전자금 / 시설자금 / 기계장비구입자금<br />
                <br className="hidden-sm hidden-xs" />
                <strong>
                    식품업 10년 경력의 컨설팅은 다릅니다.<br />
                    서비스 불 만족시 100% 환불보장
                </strong>
            </div>

            <div className="section-container">
                <div className="section" ref={el => sectionRefs.current[0] = el}>
                    <img src={section2icon1} alt="식품제조" className="responsive-image" />
                    <h3>식품제조</h3>
                </div>
                <div className="section" ref={el => sectionRefs.current[1] = el}>
                    <img src={section2icon2} alt="식자재 유통" className="responsive-image" />
                    <h3>식자재 유통</h3>
                </div>
                <div className="section" ref={el => sectionRefs.current[2] = el}>
                    <img src={section2icon3} alt="프랜차이즈" className="responsive-image" />
                    <h3>프랜차이즈</h3>
                </div>
                <div className="section" ref={el => sectionRefs.current[3] = el}>
                    <img src={section2icon4} alt="음식점" className="responsive-image" />
                    <h3>음식점</h3>
                </div>
            </div>

            <div className="section-container">
                <img src={downImage} alt="Down" className="responsive-image" />
            </div>

            <div className="section-container">
                <img src={consultingImage} alt="Consulting" className="responsive-image" />
            </div>

            <Page3 />
            <FixedContactIcons /> {/* 추가 */}
        </div>
    );
};

export default Home;
