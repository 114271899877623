// import React from 'react';
// import '../styles/Footer.css';
//
// const Footer = () => {
//     return (
//         <footer className="footer">
//             <p>© 2024 koreanvisinarycoder. All rights reserved.</p>
//
//         </footer>
//     );
// };
//
// export default Footer;


import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const FooterLeft = styled.div`
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 10px;
  }
`;

const FooterRight = styled.div`
  text-align: right;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 10px;
  }
`;

const FooterMiddle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  a {
    margin: 0 10px;
    color: #fff;
    font-size: 1.5em;

    @media (max-width: 768px) {
      font-size: 1.2em;
    }
  }
`;

const Copyright = styled.div`
  margin-top: 20px;
  font-size: 0.9em;
  color: #bbb;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterContent>
                <FooterLeft>
                    <p>기업성공메이커</p>
                    <p>도움센터 1668-5944</p>
                    <p>사업자등록번호: 3432000962</p>
                </FooterLeft>
                <FooterMiddle>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitter"></i>
                    </a>
                </FooterMiddle>
                <FooterRight>
                    <p>대표 이상열</p>
                    <p>경기도 수원시 권선구 서수원로 607</p>
                    <p>luckysylee@gmail.com</p>
                </FooterRight>
            </FooterContent>
            <Copyright>
                Copyright © 2024 약은 약사에게 식품은 식품전문가에게, 기업성공메이커 All rights reserved.
            </Copyright>
        </FooterContainer>
    );
};

export default Footer;
