// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import Page4 from './pages/Page4';
import Board from './components/Board';
import PostDetail from './components/PostDetail';
import Login from './components/Login';
import FixedContactIcons from './components/FixedContactIcons';
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/page1" element={<Page1 />} />
                    <Route path="/page2" element={<Page2 />} />
                    <Route path="/page3" element={<Page3 />} />
                    <Route path="/page4" element={<Page4 />} />
                    <Route path="/admin-login" element={<Login />} />
                    <Route path="/board" element={<Board />} />
                    <Route path="/post/:id" element={<PostDetail />} />
                    <Route path="*" element={<Home />} /> {/* 잘못된 경로 처리 */}
                </Routes>
                <Footer />
                <FixedContactIcons />
            </div>
        </Router>
    );
}

export default App;
