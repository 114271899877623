import React, { useState } from 'react';
import styled from 'styled-components';
import emailIcon from '../assets/images/emailIcon.png';
import kakaoIcon from '../assets/images/kakaoIcon.png';
import phoneIcon from '../assets/images/phoneIcon.png';
import Page3 from '../pages/Page3';

const FixedIconsContainer = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;

  & > img {
    width: 50px;
    height: 50px;
    margin: 5px 0;
    cursor: pointer;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  background: white;
  padding: 0;
  width: 100%;
  max-width: 600px;
  height: 100%;
  border-radius: 0;
  overflow-y: auto;
  max-height: 100vh;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
`;

const FixedContactIcons = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handlePhoneClick = () => {
        window.location.href = 'tel:1668-5944';
    };

    const handleEmailClick = () => {
        window.location.href = 'mailto:luckysylee@gmail.com';
    };


    const handleKakaoClick = () => {
        window.open('http://pf.kakao.com/_SwFixj/chat', '_blank');
    };

    return (
        <>
            <FixedIconsContainer>
                {/*<img src={emailIcon} alt="Email Icon" onClick={handleEmailClick} />*/}
                <img src={kakaoIcon} alt="Kakao Icon" onClick={handleKakaoClick} />
                <img src={phoneIcon} alt="Phone Icon" onClick={handlePhoneClick} />
            </FixedIconsContainer>

            {isModalOpen && (
                <ModalOverlay onClick={closeModal}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <CloseButton onClick={closeModal}>&times;</CloseButton>
                        <Page3 />
                    </ModalContent>
                </ModalOverlay>
            )}
        </>
    );
};

export default FixedContactIcons;
